import React, { FC } from 'react'
import { PageProps } from 'gatsby'
import GenericPage from '@templates/Generic/Page'
import { Box, Grid, Typography } from '@mui/material'
import { Button } from '@components'
import { TFeatureKey } from '@utils/api'
import { getUpgradeUrl } from '@utils/license'

const UpgradePage: FC<PageProps<{}, {}, { feature?: TFeatureKey }>> = ({
  location
}) => {
  const { state = {} } = location
  const { feature } = state

  return (
    <GenericPage
      title="Upgraded License Required"
      breadcrumbs={[
        {
          title: 'Upgraded License Required'
        }
      ]}
    >
      <Box mb={4}>
        <Typography>
          {
            'The feature you are trying to use is not available under your current license.'
          }
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            href={getUpgradeUrl({
              feature,
              componentName: 'Upgrade Page Button'
            })}
          >
            {'Upgrade Now'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="large"
            variant="text"
            onClick={() => window !== undefined && window.history.back()}
          >
            {'Go back'}
          </Button>
        </Grid>
        <Grid item>
          <Button size="large" variant="text" to="/dashboard/">
            {'Return to Dashboard'}
          </Button>
        </Grid>
      </Grid>
    </GenericPage>
  )
}

export default UpgradePage
